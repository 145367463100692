import merchantApi from '../../api/merchant'

const state = () => ({
    merchants: []
})

const getters = {

}

const mutations = {
    setMerchantList(state, payload){
        state.merchants = payload.merchants
    },
    clearMerchants(state){
        state.merchants = []
    }
}

const actions = {
    clearMerchants({commit}){
        commit('clearMerchants')
    },
    getMerchantList({commit, rootState}){
        return new Promise((resolve, reject)=>{
            merchantApi.getList(rootState.client.token).then(result=>{
                commit('setMerchantList', {
                    merchants: result
                })
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    getMerchantActiveList({commit}){
        return new Promise((resolve, reject)=>{
            merchantApi.getActiveList().then(result=>{
                commit('setMerchantList', {
                    merchants: result
                })
            }).catch(()=>{
                reject()
            })
        })
    },
    createMerchant({dispatch, rootState}, payload){
        return new Promise((resolve, reject)=>{
            merchantApi.create(payload.merchant, rootState.client.token).then(result=>{
                dispatch('getMerchantList')
                resolve(result)
            }).catch(()=>{
                reject()
            })
        })
    },
    updateMerchant({dispatch, rootState}, payload){
        console.log('111')
        return new Promise((resolve, reject)=>{
            merchantApi.update(payload.merchant, rootState.client.token).then(result=>{
                dispatch('getMerchantList')
                resolve(result)
            }).catch(()=>{
                reject()
            })
        })
    },
    deleteMerchant({dispatch, rootState}, payload) {
        return new Promise((resolve, reject)=>{
            merchantApi.delete(payload.id, rootState.client.token).then(()=>{
                dispatch('getMerchantList')
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    saveDeliver({dispatch, rootState}, payload) {
        return new Promise((resolve, reject)=>{
            merchantApi.saveDeliver(payload.deliver, rootState.client.token).then(resp=>{
                dispatch('getMerchantList')
                resolve(resp)
            }).catch(()=>{
                reject()
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}