<template>
    <el-header id="header" style="padding:0px;margin:0px;" height="140px">
        <sticky style="padding:0px;margin:0px;" :zIndex="10">
          <div style="width:100%;background-color:#fff;">
            <el-row  style="max-width:1300px;padding:10px;margin:0 auto;background-color:#fff;">
                <el-col :span="5" style="text-align:left;">
                    <el-image fit="fill" class="logo-img-2" :src="require('../../assets/ushop3.png')" style="cursor:pointer;" @click="homePageClick"></el-image>
                </el-col>
                <el-col :span="15">
                    <el-menu text-color="#000" mode="horizontal" style="margin-top:14px;" @select="handleMenuSelect">
                        <el-submenu index="shop">
                          <template slot="title">SHOP</template>
                          <el-menu-item index="all">All products</el-menu-item>
                          <el-menu-item v-for="item in categories" :index="item.name" :key="item.ID">{{item.name}}</el-menu-item>
                        </el-submenu>
                         <el-menu-item index="hotels">HOTEL</el-menu-item>
                    </el-menu>
                </el-col>
                <el-col :span="4" style="text-align:right;margin-top:0px;margin-top:30px;">
                    <el-badge :is-dot="goodList.length > 0" style="cursor:pointer;margin-right:20px;">
                        <i class="el-icon-shopping-cart-1" style="font-size:24px;cursor:pointer;" @click="cartClick"></i>
                    </el-badge>
                    <el-badge>
                        <i class="el-icon-user" style="font-size:24px;cursor:pointer;" @click="userClick"></i>
                    </el-badge>
                </el-col>
            </el-row>
            <div class="anouncement-div">
                <!--MEMBER FREE SHIPPING | NO PRICE LIMIT
                <div style="position:absolute;top:4px;right:10px;"><i class="el-icon-close" style="color:#fff;"></i></div>-->
            </div>
          </div>
        </sticky>
        <el-drawer :visible.sync="cartVisible" direction="rtl">
            <div slot="title" style="font-weight:bold">Cart</div>
            <div class="cart-item-div">
                <cart-item v-for="item in goodList" :key="item.key" :item="item"></cart-item>
            </div>
            <div style="padding: 0px 20px;">
                <el-divider></el-divider>
                <div style="display:-webkit-box;">
                    <div style="font-weight:bold;">TOTAL</div>
                    <div style="-webkit-box-flex:1;text-align:right;">
                        <span class="cart-total-price" v-if="isMember">{{cartMemberTotal}}</span>
                        <span class="cart-total-price" v-else>{{cartTotal}}</span>
                    </div>
                </div>
                <div>
                    <el-button type="danger" style="width:100%;margin-top:10px;" @click="checkoutClick">Check out</el-button>
                </div>
                <div style="margin-bottom:40px;">
                    <el-button type="info" style="width:100%;margin-top:10px;" @click="clearCartClick">Clear all</el-button>
                </div>
            </div>
        </el-drawer>
    </el-header>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import Sticky from '../../components/widgets/Sticky.vue'
import CartItem from '../../components/widgets/CartItem.vue'

export default {
    name: 'pageHeader',
    components:{
        Sticky,
        CartItem
    },
    data(){
        return {
            selectCategory: {
                ID: 0,
                name: 'All Categories'
            }
        }
    },
    computed: {
        ...mapState('client', [
            'client',
            'token',
            'tokenTime'
        ]),
        ...mapGetters('client', ['isMember']),
        ...mapState('cart', [
            'goodList',
            'showCart'
        ]),
        ...mapGetters('cart', ['cartTotal', 'cartMemberTotal']),
        ...mapState('category', ['categories']),
        cartVisible: {
            get(){
                return this.showCart
            },
            set(val){
                this.setShowCart({
                    showCart: val
                })
            }
        },
        isTokenOutOfDate() {
            if(this.token.length == 0){
                return true
            }
            var ts = new Date().getTime() - this.tokenTime
            return ts / 1000 > 3600 * 24 * 3
        },
        isMember: function() {
            if (this.client === null) {
                return false
            }
            return this.client.is_member
        },
        cartTotal: function(){
           var total = 0 
           if(this.goodList.length == 0) {
               return '$0.00'
           }
           for(var i=0; i < this.goodList.length; i++){
               total += this.goodList[i].sku.price * this.goodList[i].amount
           }
           console.log(this.goodList)
           return '$' + (total/100.0).toFixed(2)
        },
        cartMemberTotal: function(){
           var total = 0 
           if(this.goodList.length == 0) {
               return '$0.00'
           }
           for(var i=0; i < this.goodList.length; i++){
               total += this.goodList[i].sku.member_price * this.goodList[i].amount
           }
           console.log(this.goodList)
           return '$' + (total/100.0).toFixed(2)
        },
        cartCount: function(){
            var count = 0
            for(var i=0; i < this.goodList.length; i++){
                count += this.goodList[i].amount
            }
            return count
        },
    },
    created(){
        this.init()
    },
    methods: {
        ...mapActions('category', [
            'getActiveCategoryList'
        ]),
        ...mapActions('cart', [
            'setShowCart',
            'clearGoods'
        ]),
        init(){
            this.getActiveCategoryList()
        },
        cartClick(){
            console.log('cart click')
            this.setShowCart({
                showCart: true
            })
        },
        userClick(){
            console.log('user click')
            if(this.isTokenOutOfDate){
                window.location.href = '/login'
            } else {
                window.location.href = '/account'
            }
        },
        homePageClick(){
            window.location.href = '/'
        },
        handleMenuSelect(key, keyPath){
            console.log(key, keyPath)
            if(key === 'hotels') {
                window.location.href = '/hotels'
            } else {
                window.location.href = '/category/' + key
            }
        },
        clearCartClick() {
            this.clearGoods()
        },
        checkoutClick(){
            if(this.goodList.length == 0) return
             this.setShowCart({
                showCart: false
            })
            if(this.isTokenOutOfDate){
                this.$message({
                    message: 'Login is needed before checkout',
                    type: 'warning'
                })
                window.location.href = '/login?callbackUrl=' + encodeURI('/checkout')
            } else {
                window.location.href = '/checkout'
            }
        }
    }
}
</script>

<style>
#header {
    background-color: #fff;
    width: 100%;
}
.logo-img-2 {
    height:80px;
    width: 198px;
}
.header-title {
    font-size: 24px;
    line-height: 60px;
}
.login-btn {
    width:60px;
    margin-top:10px;
}
.el-drawer.rtl{
    overflow-y: scroll;
}
.anouncement-div{
    position: relative;
    background-color:#cc471a;
    color:#fff;
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
}
.el-submenu__title{
    font-size: 16px !important;
    font-weight: 400 !important;
}
.el-menu-item{
    font-size: 16px !important;
}
.el-drawer.rtl {
    overflow: auto !important;
}
.cart-total-price {
    color: #F56C6C;
}
.cart-item-div {
    padding: 0px 20px;
}
</style>