import axios from 'axios'
import _ from 'lodash'
import {
    merchant_all_list_url,
    merchant_active_list_url,
    merchant_create_url,
    merchant_delete_url,
    merchant_update_url,
    marchant_deliver_url
} from './urls'

var merchantApi = {
    getList(token){
        return new Promise((resolve, reject)=>{
            axios.get(merchant_all_list_url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code ==200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    getActiveList(){
        return new Promise((resolve, reject)=>{
            axios.get(merchant_active_list_url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code ==200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    create(merchant, token){
        return new Promise((resolve,reject)=>{
            var data = _.cloneDeep(merchant)
            axios.post(merchant_create_url, data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(new Error(resp.data))
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    update(merchant, token){
        return new Promise((resolve,reject)=>{
            var data = _.cloneDeep(merchant)
            axios.put(merchant_update_url, data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(new Error(resp.data))
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    delete(id, token){
        return new Promise((resolve, reject)=>{
            var url = merchant_delete_url.format({
                id: id
            })
            axios.delete(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve()
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    saveDeliver(deliver, token){
        return new Promise((resolve, reject)=>{
            var url = marchant_deliver_url
            axios.post(url, deliver, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                resolve(resp)
            }).catch(err=>{
                reject(err)
            })
        })
    }
}

export default merchantApi