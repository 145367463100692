import axios from 'axios'
import {
    category_list_url,
    category_active_list_url,
    category_create_url,
    category_update_url,
    category_delete_url
} from './urls'

var categoryApi = {
    getList(){
        return new Promise((resolve, reject)=>{
            axios.get(category_list_url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp.data)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    getActiveList(){
        return new Promise((resolve, reject)=>{
            axios.get(category_active_list_url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp.data)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    create(name, enable, token) {
        return new Promise((resolve, reject)=>{
            axios.post(category_create_url.format({categoryName: name, enable: enable}),'', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                if(resp.status == 200 && resp.data.code == 200){
                    resolve()
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    update(id, name, enable, token){
        return new Promise((resolve, reject)=>{
            var url = category_update_url.format({
                categoryId: id,
                categoryName: name,
                enable: enable
            })
            axios.put(url, '', {
                headers:{
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                if(resp.status == 200 && resp.data.code == 200){
                    resolve()
                }else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    delete(id, token){
        return new Promise((resolve, reject)=>{
            var url = category_delete_url.format({
                categoryId: id
            })
            axios.delete(url, {
                headers:{
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve()
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    }
}

export default categoryApi