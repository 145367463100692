import hotelApi from '../../api/hotel.js'
const state = {
    hotels: [],
    currentHotel: {
        ID: 0,
        CreatedAt: '',
        UpdatedAt: null,
        DeletedAt: null,
        name: '',
        address: '',
        contact_number: '',
        email: '',
        validity: '',
        merchant_id: 0,
        priviliges: [],
        conditions: [],
        inclusions: [],
        remarks: [],
        cancel_policies: [],
        blackout_dates: [],
        images: [],
        enable: true,
        rooms: []
      }
}

const getters = {
}

const mutations = {
    initSelectHotel({state}){
        state.selectHotel = {
            ID: 0,
            CreatedAt: '',
            UpdatedAt: null,
            DeletedAt: null,
            name: '',
            address: '',
            contact_number: '',
            email: '',
            validity: '',
            merchant_id: 0,
            priviliges: [],
            conditions: [],
            inclusions: [],
            remarks: [],
            cancel_policies: [],
            blackout_dates: [],
            images: [],
            enable: true,
            rooms: []
          }
    },
    setSelectHotel({state}, payload){
        state.selectHotel= payload.selectHotel
    },
    setHotelList({state}, payload){
        state.hotels = payload
    }
}

const actions = {
    initSelectHotel({commit}) {
        commit('initSelectHotel')
    },
    setSelectHotel({commit}, payload) {
        commit('setSelectHotel', payload)
    },
    listHotels({commit}) {
        hotelApi.list().then(resp=>{
            commit('setHotelList', resp)
        })
    },
    createHotel({dispatch, rootState}, payload) {
        return new Promise((reslove, reject)=>{
            hotelApi.create(payload.hotel, rootState.client.token).then(resp=>{
                console.log(resp)
                dispatch('listHotels')
                reslove()
            }).catch(()=>{
                reject()
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}