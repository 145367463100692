import axios from 'axios'
import {
    order_create_url,
    order_client_list,
    order_all_list,
    order_check_url,
    order_by_id_url,
    order_delivered_url,
    order_client_status_list,
    order_send_daily_email,
    receipt_by_id_url
} from './urls'

var orderApi = {
    sendDailyEmail(token){
        return new Promise((resolve, reject)=>{
            axios.get(order_send_daily_email, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve()
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    createOrder(order, token){
        return new Promise((resolve, reject)=>{
            axios.post(order_create_url + "?create_by=direct", order, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    },
    getClientOrdersBystatus(token, status, pageSize, pageIndex) {
        var url = order_client_status_list.format({
            status: status,
            pageIndex: pageIndex,
            pageSize: pageSize
        })
        return new Promise((resolve, reject)=>{
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    },
    getClientOrderList(token, pageSize, pageIndex){
        return new Promise((resolve, reject)=>{
            axios.get(order_client_list + "/" + pageSize + "/" + pageIndex, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    },
    getAllOrderList(token, locationId, searchByName, searchByValue, date, pageSize, pageIndex){
        return new Promise((resolve, reject)=>{
            var url = order_all_list + "/" + pageSize + "/" + pageIndex +"?locationId=" + locationId + "&" + searchByName + "=" + searchByValue + "&date=" + date
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    checkOrderStatus(token, orderId){
        return new Promise((resolve, reject)=>{
            var url = order_check_url.format({
                orderId: orderId
            })
            axios.get(url,{
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    getOrderById(token, orderId){
        return new Promise((resolve, reject)=>{
            var url = order_by_id_url.format({
                orderId: orderId
            })
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    getReceiptById(token, orderId) {
        return new Promise((resolve, reject)=>{
            var url = receipt_by_id_url.format({
                orderId: orderId
            })
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    confirmDeliver(token, orderId){
        return new Promise((resolve, reject)=>{
            var url = order_delivered_url.format({
                orderId: orderId
            })
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    }
}

export default orderApi