<template>
  <el-container id="baseWebPage" direction="vertical">
    <page-header></page-header>
    <slot name="main">
      <el-main style="padding:40px 0px;margin:0 auto;width:100%;max-width:1300px;">
        <el-empty description="No Content"></el-empty>
      </el-main>
    </slot>
    <page-footer></page-footer>
  </el-container>
</template>

<script>
import PageHeader from '../../components/widgets/PageHeader.vue'
import PageFooter from '../../components/widgets/PageFooter.vue'

export default {
  name: 'baseWebPage',
  components: {
    PageHeader,
    PageFooter
  }
}
</script>

<style>

</style>