import orderApi from '../../api/order'

const state = () => ({
    myOrderPageSize: 5,
    pageSize: 10,
    pageIndex: 1,
    clientOrderCount: 0,
    clientOrderList: [],
    adminOrderCount: 0,
    adminOrderList: []
}) 

const getters = {
}

const mutations = {
    setAdminOrderList(state, payload){
        state.adminOrderList = payload.orderList
        state.adminOrderCount = payload.count
    },
    setClientOrderList(state, payload){
        state.clientOrderList = payload.orderList
        state.clientOrderCount = payload.count
    },
    setCurrentIndex(state, payload){
        state.pageIndex = payload.pageIndex
    },
    clearOrders(state){
        state.clientOrderCount = 0
        state.clientOrderList = []
        state.adminOrderCount = 0
        state.adminOrderList = []
        state.pageIndex = 1
    }
}

const actions = {
    clearOrders({commit}){
        commit('clearOrders')
    },
    getAllOrderList({commit, state, rootState}, payload){
        commit('setCurrentIndex', {
            pageIndex: 1
        })
        orderApi.getAllOrderList(rootState.client.token, payload.locationId, payload.searchByName, payload.searchByValue, payload.date, state.pageSize, state.pageIndex).then(resp=>{
            commit('setAdminOrderList', {
                orderList: resp.items,
                count: resp.count
            })
        })
    },
    setAllOrderCurrentIndex({commit, state, rootState}, payload) {
        commit('setCurrentIndex', {
            pageIndex: payload.pageIndex
        })
        orderApi.getAllOrderList(rootState.client.token, payload.locationId, payload.searchByName, payload.searchByValue, payload.date, state.pageSize, state.pageIndex).then(resp=>{
            commit('setAdminOrderList', {
                orderList: resp.items,
                count: resp.count
            })
        })
    },
    getClientOrderList({commit, state, rootState}, payload){
        commit('setCurrentIndex', {
            pageIndex: 1
        })
        return new Promise((resolve, reject)=>{
            orderApi.getClientOrdersBystatus(rootState.client.token, payload.status, state.pageSize, state.pageIndex).then(resp=>{
                commit('setClientOrderList', {
                    orderList: resp.items,
                    count: resp.count
                })
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    setCurrentIndex({commit, state, rootState}, payload){
        commit('setCurrentIndex', {
            pageIndex: payload.pageIndex
        })
        return new Promise((resolve, reject) => {
            orderApi.getClientOrdersBystatus(rootState.client.token, payload.status, state.pageSize, state.pageIndex).then(resp=>{
                commit('setClientOrderList', {
                    orderList: resp.items,
                    count: resp.count
                })
                resolve()
            }).catch(()=> reject())
        })
    },
    getOrderStatus({rootState}, payload){
        return new Promise((resolve, reject)=>{
            orderApi.checkOrderStatus(rootState.client.token, payload.orderId).then(resp=>{
                resolve(resp)
            }).catch(()=>{
                reject()
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}