<template>
    <el-footer id="footer" style="padding:0px; position:relateive;height:680px;">
        <el-main style="padding:40px 10px;max-width:1300px;margin:0 auto;color:#fff;">
            <el-row>
                <el-col :span="24" style="text-align:left; margin-bottom:20px;">
                    <div class="footer-normal footer-large">Follow Us</div>
                    <ul class="footer-social">
                        <li>
                            <a target="_blank" href="https://m.facebook.com/ucommune.sg/" class="footer-social-link">
                                <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-facebook" viewBox="0 0 32 32"><path fill="#fff" d="M18.56 31.36V17.28h4.48l.64-5.12h-5.12v-3.2c0-1.28.64-2.56 2.56-2.56h2.56V1.28H19.2c-3.84 0-7.04 2.56-7.04 7.04v3.84H7.68v5.12h4.48v14.08h6.4z"></path></svg>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/ucommune.sg/" class="footer-social-link">
                                <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-instagram" viewBox="0 0 32 32"><path fill="#fff" d="M16 3.094c4.206 0 4.7.019 6.363.094 1.538.069 2.369.325 2.925.544.738.287 1.262.625 1.813 1.175s.894 1.075 1.175 1.813c.212.556.475 1.387.544 2.925.075 1.662.094 2.156.094 6.363s-.019 4.7-.094 6.363c-.069 1.538-.325 2.369-.544 2.925-.288.738-.625 1.262-1.175 1.813s-1.075.894-1.813 1.175c-.556.212-1.387.475-2.925.544-1.663.075-2.156.094-6.363.094s-4.7-.019-6.363-.094c-1.537-.069-2.369-.325-2.925-.544-.737-.288-1.263-.625-1.813-1.175s-.894-1.075-1.175-1.813c-.212-.556-.475-1.387-.544-2.925-.075-1.663-.094-2.156-.094-6.363s.019-4.7.094-6.363c.069-1.537.325-2.369.544-2.925.287-.737.625-1.263 1.175-1.813s1.075-.894 1.813-1.175c.556-.212 1.388-.475 2.925-.544 1.662-.081 2.156-.094 6.363-.094zm0-2.838c-4.275 0-4.813.019-6.494.094-1.675.075-2.819.344-3.819.731-1.037.4-1.913.944-2.788 1.819S1.486 4.656 1.08 5.688c-.387 1-.656 2.144-.731 3.825-.075 1.675-.094 2.213-.094 6.488s.019 4.813.094 6.494c.075 1.675.344 2.819.731 3.825.4 1.038.944 1.913 1.819 2.788s1.756 1.413 2.788 1.819c1 .387 2.144.656 3.825.731s2.213.094 6.494.094 4.813-.019 6.494-.094c1.675-.075 2.819-.344 3.825-.731 1.038-.4 1.913-.944 2.788-1.819s1.413-1.756 1.819-2.788c.387-1 .656-2.144.731-3.825s.094-2.212.094-6.494-.019-4.813-.094-6.494c-.075-1.675-.344-2.819-.731-3.825-.4-1.038-.944-1.913-1.819-2.788s-1.756-1.413-2.788-1.819c-1-.387-2.144-.656-3.825-.731C20.812.275 20.275.256 16 .256z"></path><path fill="#fff" d="M16 7.912a8.088 8.088 0 0 0 0 16.175c4.463 0 8.087-3.625 8.087-8.088s-3.625-8.088-8.088-8.088zm0 13.338a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 1 1 0 10.5zM26.294 7.594a1.887 1.887 0 1 1-3.774.002 1.887 1.887 0 0 1 3.774-.003z"></path></svg>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.linkedin.com/company/ucommune-sg/" class="footer-social-link">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fff"  d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
                            </a>
                        </li>
                    </ul>
                    <div class="footer-normal">
                        <el-link target="_blank" href="/pages/terms-of-service" style="color:#fff;">Terms of Service</el-link>
                    </div>
                    <div class="footer-normal">
                        <el-link target="_blank" href="/pages/privacy-policy" style="color:#fff;">Privacy Policy</el-link>
                    </div>
                </el-col>
                <el-col :span="24" style="text-align:left; margin-bottom:20px;">
                    <div class="footer-normal footer-large">Contact Us</div>
                    <div class="footer-normal"><el-link style="color:white;" href="mailto:u-shop@ucommune.com.sg" >u-shop@ucommune.com.sg</el-link></div>
                    <div class="footer-normal"><el-link style="color:white;" href="tel:6586921718">(65) 8692 1718</el-link></div>
                    <div class="footer-normal"><el-link style="color:white;" href="https://ucommune.com.sg/sg-ucommune-singapore-international-co-working-space/location/bugis-junction/" target="_blank">Bugis Junction</el-link>,</div>
                    <div class="footer-normal"><el-link style="color:white;" href="https://ucommune.com.sg/sg-ucommune-singapore-international-co-working-space/location/one-north/" target="_blank">Launchpad@one north</el-link>,</div>
                    <div class="footer-normal"><el-link style="color:white;" href="https://ucommune.com.sg/sg-ucommune-singapore-international-co-working-space/location/oue-downtown-2/" target="_blank">OUE Downtown 2</el-link>,</div>
                    <div class="footer-normal"><el-link style="color:white;" href="https://ucommune.com.sg/sg-ucommune-singapore-international-co-working-space/location/suntec-city/" target="_blank">Suntec City Tower 2</el-link></div>
                </el-col>
                <el-col :span="24" style="text-align:left;">
                    <div class="footer-normal footer-large">Ucommune</div>
                    <div class="footer-normal">Grow Your Business with Asia’s International</div>
                    <div class="footer-normal">Coworking Space</div>
                </el-col>
            </el-row>
            <el-divider style="color:#f4f4f4;"></el-divider>
            <div style="font-size:12px;">© 2021 UShop All Rights Reserved.</div>
            <div style="font-size:12px;margin-top:10px;">Designed by Adix.</div>
        </el-main>
    </el-footer>
</template>

<script>
export default {
    name: 'pageFooter'
}
</script>

<style>
#footer {
    background-color: #2b2b2b;
    width: 100%;
}
.footer-normal{
    padding: 5px;
    font-size: 14px;
    line-height: 22px;
}
.footer-large{
    font-size:18px;
    font-weight: bold;
}
.footer-social{
    margin: 0 0 0 -10px;
    padding: 0;
    list-style: none outside;
}
.footer-social li{
    display: inline-block;
    padding: 10px;
}
.footer-social li a {
    display: block;
}
.footer-social-link{
    color: #fff;
}
.footer-social-link-hidden{
    clip: rect(0,0,0,0);
    overflow: hidden;
    height: 1px;
    width: 1px;
    display: absolute;
}
.icon{
    width: 24px;
    height: 24px;
    color: #fff;
}
</style>