import productApi from '../../api/product.js'

const state = () => ({
    products: [],
    productCount: 0,
    pageSize: 20,
    pageIndex: 1,
    activeCategoryIndex: "0",
    loading: false
})

const getters = {
}

const mutations = {
    setActiveCategory(state, payload){
        state.activeCategoryIndex = payload.index
    },
    setPageIndex(state, payload){
        state.pageIndex = payload.pageIndex
    },
    setProducts(state, payload){
        state.products = payload.items
        state.productCount = payload.count
    },
    clearProducts(state){
        state.products = []
        state.productCount = 0
        state.pageIndex = 1
    },
    setLoading(state, payload){
        state.loading = payload
    }
}

const actions = {
    clearProducts({commit}){
        commit('clearProducts')
    },
    setActiveCategory({dispatch, commit, state}, payload){
        commit('setActiveCategory', payload)
        commit('setPageIndex', {pageIndex: 1})
        if(state.activeCategoryIndex == '0'){
            dispatch('getProducts')
        } else {
            dispatch('getProductsByCategory')
        }
    },
    setPageIndex({dispatch, commit, state}, payload){
        commit('setPageIndex', payload)
        if(state.activeCategoryIndex == '0'){
            dispatch('getProducts')
        } else {
            dispatch('getProductsByCategory')
        }
    },
    setActivePageIndex({dispatch, commit, state}, payload){
        commit('setLoading', true)
        commit('setPageIndex', payload)
        if(state.activeCategoryIndex == '0'){
            dispatch('getActiveProducts')
        } else {
            dispatch('getActiveProductsByCategory')
        }
    },
    getProducts({commit, state}) {
        productApi.getProducts(state.pageIndex, state.pageSize).then((result)=>{
            commit('setProducts', result)    
            commit('setLoading', false)    
        }).catch(err=>{
            console.log(err)
            commit('setLoading', false)
        })
    },
    getActiveProducts({commit, state}) {
        productApi.getActiveProducts(state.pageIndex, state.pageSize).then((result)=>{
            commit('setProducts', result)     
            commit('setLoading', false)       
        }).catch(err=>{
            console.log(err)
        })
    },
    getProductsByCategory({commit, state}){
        productApi.getProductsByCategory(state.activeCategoryIndex, state.pageIndex, state.pageSize).then(result=>{
            commit('setProducts', result)
            commit('setLoading', false)
        }).catch(err=>{
            console.log(err)
            commit('setLoading', false)
        })
    },
    getActiveProductsByCategory({commit, state}){
        productApi.getActiveProductsByCategory(state.activeCategoryIndex, state.pageIndex, state.pageSize).then(result=>{
            commit('setProducts', result)
            commit('setLoading', false)
        }).catch(err=>{
            console.log(err)
            commit('setLoading', false)
        })
    },
    createProduct({dispatch, state, rootState}, payload){
        return new Promise((resolve, reject)=>{
            productApi.createProduct(payload.product, rootState.client.token).then(res=>{
                if(state.activeCategoryIndex === '0'){
                    dispatch('getProducts')
                } else if(payload.product.category_id.toString() === state.activeCategoryIndex){
                    dispatch('getProductsByCategory')
                }
                resolve(res)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    updateProduct({dispatch, state, rootState}, payload){
        return new Promise((resolve, reject)=>{
            productApi.updateProduct(payload.product, rootState.client.token).then(res=>{
                if(state.activeCategoryIndex === '0'){
                    dispatch('getProducts')
                } else if(payload.product.category_id.toString() === state.activeCategoryIndex){
                    dispatch('getProductsByCategory')
                }
                resolve(res)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    deleteProduct({dispatch, state, rootState}, payload){
        return new Promise((resolve, reject)=>{
            productApi.deleteProduct(payload.id, rootState.client.token).then(()=>{
                if(state.activeCategoryIndex === '0'){
                    dispatch('getProducts')
                } else if(payload.product.category_id.toString() === state.activeCategoryIndex){
                    dispatch('getProductsByCategory')
                }
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}