import userApi from '../../api/user'

const state = () => ({
    client: null,
    token: '',
    browserFrom: 'web',
    htmlHeader: 0,
    tokenTime: 0,
    view: 'index',
    clients: [],
    pageSize: 20,
    pageIndex: 1,
    clientCount: 0
})

const getters = {
    isMember(state){
        if (state.client === null) {
            return false
        }
        return state.client.is_member
    },
    isTokenOutOfDate(state) {
        if(state.token.length == 0){
            return true
        }
        var ts = new Date().getTime() - state.tokenTime
        return ts / 1000 > 3600 * 24 * 3
    },
}

const mutations = {
    setHtmlHeader(state, paylaod) {
        state.htmlHeader = paylaod
    },
    setFrom(state, payload){
        state.browserFrom = payload
    },
    register(state, payload){
        state.client = payload.client
        state.token = payload.token
        state.tokenTime = new Date().getTime()
        if(state.client.c_type === 'admin'){
            state.view = 'admin'
        } else {
            state.view = 'index'
        }
    },
    logout(state){
        state.client = null
        state.token = ''
        state.view = 'index'
        state.tokenTime = 0
    },
    setView(state, payload){
        state.view = payload.view
    },
    checkout(state){
        if(state.token.length == 0){
            state.view = 'login'
        } else {
            state.view = 'checkout'
        }
    },
    relogin(state){
        state.view = 'login'
    },
    setClients(state, payload){
        state.clients = payload.items
        state.clientCount = payload.count
    },
    clearClients(state){
        state.clients = []
        state.clientCount = 0
        state.pageIndex = 1
    },
    setPageIndex(state, paylaod){
        state.pageIndex = paylaod.pageIndex
    },
    refreshClient(state, payload){
        state.client = payload.client
    },
    resetMember(state, payload) {
        for(var i=0; i<state.clients.length; i++){
            if(state.clients[i].ID === payload.client.ID) {
                if (state.clients[i].is_member === 1) {
                     state.clients[i].is_member = 0
                } else {
                    state.clients[i].is_member = 1
                }
            }
        }
    }
}

const actions = {
    setHtmlHeader({commit}, payload) {
        commit('setHtmlHeader', payload)
    },
    setFrom({commit}, payload){
        commit('setFrom', payload)
    },
    refreshClient({commit}, payload){
        commit('refreshClient', payload)
    },
    appLogin({commit}, payload) {
        commit('register', payload)
    },
    mLogin({commit}, payload){
        return new Promise((resolve, reject)=>{
            userApi.mLogin(payload.id).then(result=>{
                commit('register', {
                    client: result.client,
                    token: result.token
                })
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    login({commit}, payload){
        return new Promise((resolve, reject)=>{
            userApi.login(payload).then(result=>{
                commit('register', {
                    client: result.client,
                    token: result.token
                })
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    register({commit}, payload) {
        return new Promise((resolve, reject)=>{
            userApi.register(payload).then(result=>{
                commit('register', {
                    client: result.client,
                    token: result.token
                })
                resolve()
            }).catch((result)=>{
                reject(result)
            })
        })
    },
    logout({commit}) {
        commit('logout')
        commit('clearClients')
    },
    setView({commit}, payload){
        commit('setView', payload)
    },
    checkout({commit}){
        commit('checkout')
    },
    relogin({commit}){
        commit('relogin')
    },
    getClients({commit, state}, payload){
        userApi.list(payload.locationId, state.pageIndex, state.pageSize, state.token).then(result=>{
            commit('setClients', result)
        })
    },
    resetMember({commit, state}, payload) {
        userApi.setMember(payload.client.ID, payload.client.is_member == 1 ? 0 : 1, state.token).then(()=>{
            commit('resetMember', payload)
        })
    },
    setPageIndex({dispatch, commit}, payload){
        commit('setPageIndex', payload)
        dispatch('getClients')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}