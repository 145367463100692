import axios from 'axios'
import {
    hotel_create_url,
    hotel_list_url,
    hotel_book_url,
    hotel_user_book_list,
    hotel_booking_list_url
} from './urls'

var hotelApi = {
    create(hotel, token) {
        return new Promise((resolve, reject)=>{
            axios.post(hotel_create_url, hotel, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    update(hotel, token) {
        return new Promise((resolve, reject)=>{
            axios.put(hotel_create_url, hotel, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    list() {
        return new Promise((resolve, reject)=>{
            axios.get(hotel_list_url).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    book(data, token) {
        return new Promise((resolve, reject) => {
            axios.post(hotel_book_url, data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })  
        })
    },
    userBookList(pageIndex, pageSize, token) {
        var url = hotel_user_book_list.format({
            pageIndex: pageIndex,
            pageSize: pageSize
        })
        return new Promise((resolve, reject) => {
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            }) 
        })
    },
    bookingList(hotelName, clientName, clientEmail, bookingDate, pageIndex, pageSize, token) {
        var url = hotel_booking_list_url.format({
            pageIndex: pageIndex,
            pageSize: pageSize
        })
        return new Promise((resolve, reject)=>{
            axios.post(url, {
                hotel_name: hotelName,
                client_name: clientName,
                client_email: clientEmail,
                booking_date: bookingDate
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code == 200) {
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    }
}

export default hotelApi