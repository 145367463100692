<template>
    <div></div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      urls: [
          require('../../assets/banner01.png'),
          require('../../assets/banner02.png'),
          require('../../assets/banner03.png'),
        ]
    }
  },
  methods: {
    gotoTop01(){
      window.location.href = '/product/492868995318016'
    },
    gotoTop02(){
      window.location.href = '/product/522428580069632'
    }
  }
}
</script>

<style>
.home {
  padding: 0px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 450px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.home-paragraph{
  padding: 60px 20px 0px 20px;
  margin: 0 auto;
  max-width: 1300px;
}
.home-text-title{
  font-size: 27px;
  font-weight: bold;
}
.home-text-content{
  margin-top: 20px;
  font-size: 15px;
}
.home-box-m{
  display: -webkit-box;
}
.home-box-flex{
  -webkit-box-flex:1;
}
</style>