<template>
  <div id="cartItem">
      <el-divider></el-divider>
      <div style="display:-webkit-box;"> 
          <el-image :src="item.product.image" fit="fit" style="width:80px;height:80px"></el-image>
          <div style="-webkit-box-flex:1;text-align:left;margin-left:20px;">
              <div>{{item.product.name}}</div>
              <div style="margin-top:10px;color:grey;">{{item.sku.name}}</div>
              <div style="margin-top:10px;display:-webkit-box;">
                  <el-input-number size="mini" :min="0" :max="999" :step="1" v-model="item.amount" style="width:110px;" @change="amountChangeClick"></el-input-number>
                  <div class="cart-item-price" v-if="isMember">${{(item.sub_member_total/100).toFixed(2)}}</div>
                  <div class="cart-item-price" v-else>${{(item.sub_total/100).toFixed(2)}}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {mapState, mapGetters ,mapActions} from 'vuex'

export default {
    name: 'cartItem',
    props: ['item'],
    computed: {
        ...mapState('client', ['client']),
        ...mapGetters('client', ['isMember'])
    },
    methods: {
        ...mapActions('cart', [
            'changeGoodAmount',
            'removeGood'
        ]),
        amountChangeClick(val, oldval){
            console.log(val, oldval)
            if(val === 0){
                this.removeGood({
                    key: this.item.key
                 })
            } else {
                this.changeGoodAmount({
                    key: this.item.key,
                    amount: val
                })
            }
        }
    }
}
</script>

<style>
.cart-item-price {
    line-height: 26px;
    -webkit-box-flex:1;
    text-align: right;
    color: #F56C6C;
}
</style>