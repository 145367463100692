import axios from 'axios'
import _ from 'lodash'
import {
    product_get_by_id,
    product_list_url,
    product_list_by_category_url,
    product_create_url,
    product_update_url,
    product_delete_url,
    product_active_list_url,
    product_active_list_by_category_url,
    
} from './urls.js'

var productApi= {
    getProductById(id){
        return new Promise((resolve, reject)=>{
            var url = product_get_by_id.format({
                id: id
            })
            axios.get(url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    },
    getProducts(pageIndex, pageSize){
        return new Promise((resolve, reject)=>{
            var url = product_list_url.format({
                pageIndex:pageIndex, 
                pageSize:pageSize
            })
            axios.get(url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(new Error(resp.data))
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    getActiveProducts(pageIndex, pageSize){
        return new Promise((resolve, reject)=>{
            var url = product_active_list_url.format({
                pageIndex:pageIndex, 
                pageSize:pageSize
            })
            axios.get(url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(new Error(resp.data))
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    getProductsByCategory(categoryId, pageIndex, pageSize) {
        return new Promise((resolve, reject)=>{
            var url = product_list_by_category_url.format({
                categoryId: categoryId,
                pageIndex: pageIndex,
                pageSize: pageSize
            })
            axios.get(url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(new Error(resp.data))
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    getActiveProductsByCategory(categoryId, pageIndex, pageSize) {
        return new Promise((resolve, reject)=>{
            var url = product_active_list_by_category_url.format({
                categoryId: categoryId,
                pageIndex: pageIndex,
                pageSize: pageSize
            })
            axios.get(url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(new Error(resp.data))
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    createProduct(product, token){
        return new Promise((reslove, reject)=>{
            var data = _.cloneDeep(product)
            data.skus.forEach(sku=>{
                sku.price = Math.floor(sku.price * 100)
                sku.member_price = Math.floor(sku.member_price * 100)
            })
            data.min_collection_date = Math.floor(data.min_collection_date * 1)
            axios.post(product_create_url, data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    reslove(resp.data.result)
                } else {
                    reject(new Error(resp.data))
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    updateProduct(product, token){
        return new Promise((resolve, reject)=>{
            var data = _.cloneDeep(product)
            data.skus.forEach(sku=>{
                sku.price = Math.floor(sku.price * 100)
                sku.member_price = Math.floor(sku.member_price * 100)
            })
            data.min_collection_date = Math.floor(data.min_collection_date * 1)
            axios.put(product_update_url, data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data)
                }else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    deleteProduct(id, token) {
        return new Promise((resolve, reject)=>{
            var url = product_delete_url.format({
                id: id
            })
            axios.delete(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve()
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    }
}


export default productApi