import orderApi from '../../api/order'

const state = () => ({
    showCart: false,
    goodList:[],
    shipping:{
        locationId: 0,
        location: null,
        expectedShippingDate: '',
        expectedShippingTime: ''
    }
})

const getters = {
    goodListByMerchants(state) {
        var goodsMerchantDict = {}
        state.goodList.forEach(good => {
          console.log(good)
          var merchantId = good.product.merchant_id
          if(merchantId in goodsMerchantDict){
            goodsMerchantDict[merchantId].goods.push(good)
            goodsMerchantDict[merchantId].goods_fee += good.sub_total
            goodsMerchantDict[merchantId].sub_total += good.sub_total
            goodsMerchantDict[merchantId].goods_member_fee += good.sub_member_total
            goodsMerchantDict[merchantId].sub_member_total += good.sub_member_total
            if(goodsMerchantDict[merchantId].deliver.ID > 0 && goodsMerchantDict[merchantId].delivery_fee > 0 && goodsMerchantDict[merchantId].sub_total > goodsMerchantDict[merchantId].deliver.free_fee) {
              goodsMerchantDict[merchantId].sub_total -= goodsMerchantDict[merchantId].delivery_fee
              goodsMerchantDict[merchantId].delivery_fee = 0
            }
            if(goodsMerchantDict[merchantId].deliver.ID > 0 && goodsMerchantDict[merchantId].delivery_member_fee > 0 && goodsMerchantDict[merchantId].sub_member_total > goodsMerchantDict[merchantId].deliver.free_fee) {
                goodsMerchantDict[merchantId].sub_member_total -= goodsMerchantDict[merchantId].delivery_fee
                goodsMerchantDict[merchantId].delivery_member_fee = 0
              }
          } else {
            var item = {
              merchant: good.product.merchant,
              deliver: good.product.merchant.deliver,
              sub_total: good.sub_total,
              sub_member_total: good.sub_member_total,
              goods_fee: good.sub_total,
              goods_member_fee: good.sub_member_total,
              delivery_fee: 0,
              delivery_member_fee: 0,
              goods: [good]
            }
            if (item.deliver.ID > 0 && item.sub_total < item.deliver.free_fee) {
              item.delivery_fee = item.deliver.fee
              // item.sub_total += item.delivery_fee
            }
            if (item.deliver.ID > 0 && item.sub_member_total < item.deliver.free_fee) {
                item.delivery_member_fee = item.deliver.fee
                // item.sub_member_total += item.delivery_fee
              }
            goodsMerchantDict[merchantId] = item
          }
        });
        var goodsMerchantList = []
        for (var key in goodsMerchantDict) {
          goodsMerchantList.push(goodsMerchantDict[key])
        }
        return goodsMerchantList
    },
    cartTotal(state,getters){
        return (getters.cartTotalInFen/100.0).toFixed(2)
     },
     cartTotalInFen(state){
        var total = 0 
        for(var i = 0; i < state.goodList.length; i++) {
            total += state.goodList[i].sub_total
        }
        return total
     },
     cartMemberTotal(state,getters){
        return (getters.cartMemberTotalInFen/100.0).toFixed(2)
     },
     cartMemberTotalInFen(state){
        var total = 0 
        for(var i = 0; i < state.goodList.length; i++) {
            total += state.goodList[i].sub_member_total
        }
        return total
     }
}

const mutations = {
    setShowCart(state, payload){
        state.showCart = payload.showCart
    },
    resetShipping(state){
        state.shipping.locationId = 0
        state.shipping.location = null
        state.shipping.expectedShippingTime = ''
        state.shipping.expectedShippingDate = ''
    },
    setShipping(state, payload){
        console.log(payload)
        if(payload.location != null){
            state.shipping.locationId = payload.location.ID
            state.shipping.location = payload.location
        }
    },
    addGood(state, payload){
        var goodExistIndex = -1 
        for(var i=0; i<state.goodList.length; i++){
            if(state.goodList[i].key == payload.good.key){
                goodExistIndex = i 
                break
            }
        }
        if(goodExistIndex == -1){
            state.goodList.push(payload.good)
        } else {
            state.goodList[goodExistIndex].amount += 1
            state.goodList[goodExistIndex].sub_total += payload.good.sku.price
            state.goodList[goodExistIndex].sub_member_total += payload.good.sku.member_price
        }
        state.showCart = true
    },
    clearGoods(state){
        state.goodList = []
        state.shipping.expectedShippingDate = ''
        state.shipping.expectedShippingTime = ''
        state.shipping.location = null
    },
    changeGoodAmount(state, payload){
        for(var i = 0; i < state.goodList.length; i++){
            if(state.goodList[i].key == payload.key){
                state.goodList[i].amount = payload.amount 
                state.goodList[i].sub_total = payload.amount * state.goodList[i].sku.price
                state.goodList[i].sub_member_total = payload.amount * state.goodList[i].sku.member_price
            }
        }
    },
    removeGood(state, payload){
        var index = -1
        for(var i = 0; i < state.goodList.length; i++){
            if(state.goodList[i].key == payload.key) {
                index = i;
                break
            }
        }
        if(index > -1){
            state.goodList.splice(i, 1)
        }
    },
}

const actions = {
    setShowCart({commit}, payload){
        commit('setShowCart', payload)
    },
    resetShipping({commit}){
        commit('resetShipping')
    },
    setShipping({commit}, payload){
        commit('setShipping', payload)
    },
    addGood({commit}, payload) {
        commit('addGood', payload)
    },
    clearGoods({commit}){
        commit('clearGoods')
    },
    changeGoodAmount({commit}, payload){
        commit('changeGoodAmount', payload)
    },
    removeGood({commit}, payload){
        commit('removeGood', payload)
    },
    checkout({state, getters, rootState}, payload){
        var goodList = payload.goodList
        var orderItems = []
        for(var i = 0; i < goodList.length; i++){
            var good = goodList[i]
            var item = {
              product_id: good.product.ID,
              product_name: good.product.name,
              product_image: good.product.image,
              category_id: good.product.category.ID,
              category_name: good.product.category.name,
              merchant_id: good.product.merchant.ID,
              merchant_name: good.product.merchant.name,
              sku_id: good.sku.id,
              sku_name: good.sku.name,
              sku_price: good.sku.price,
              sku_member_price: good.sku.member_price,
              amount: good.amount,
              sub_total: good.sub_total,
              sub_member_total: good.sub_member_total,
              deliver_date: good.deliver_date
            }
            orderItems.push(item)
        }
        var client = rootState.client.client
        var shipping = {
            name: payload.contact.name,
            contact_no: payload.contact.mobile,
            room_no: client.room_no,
            location_id: state.shipping.locationId,
            office: state.shipping.location.name,
            address: state.shipping.location.address,
            postal_code: state.shipping.location.postal_code
        }
        var order = {
            items: orderItems,
            shipping: shipping,
            total: getters.cartTotalInFen,
            is_member_by: client.is_member,
            payment: 'paynow'
        }
        if(rootState.client.client.is_member) {
            order.total = getters.cartMemberTotalInFen
        }
        console.log(order)
        return new Promise((resolve, reject)=>{
            orderApi.createOrder(order, rootState.client.token).then(resp=>{
                resolve(resp)
            }).catch(err=>{
                reject(err)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}