<template>
  <base-mobile-page>
    <template v-slot:main>
       <el-main style="padding:0px;margin:0 auto;width:100%;">
      <el-carousel :interval="4000"  height="140px">
        <el-carousel-item v-for="item in urls" :key="item">
          <el-image style="width:100%;" fit="fill" :src="item"></el-image>
        </el-carousel-item>
      </el-carousel>
      <div class="home-paragraph-m">
        <div class="home-text-title">Welcome To UShop</div>
        <div class="home-text-content">Catch the best deals on the best products!</div>
      </div>
      <div class="home-paragraph-m home-box">
         <div class="home-box-flex" style="text-align:left;">
           <el-image style="width:100%;" fit="cover" :src="require('../../assets/top01.png')"></el-image>
         </div>
         <div class="home-box-flex" style="text-align:left;">
            <div class="home-text-title">Chocolates made by Kempinski Hotel</div>
            <div class="home-text-content">Ucommune Singapore</div>
            <div class="home-text-content">
              <el-button type="danger" @click="gotoTop01">Hot deals from $19.80</el-button>
            </div>
         </div>
      </div>
      <el-divider></el-divider>
      <div class="home-paragraph-m home-box">
         <div class="home-box-flex" style="text-align:right;">
           <el-image style="width:100%;" fit="cover" :src="require('../../assets/top02.jpg')"></el-image>
         </div>
         <div class="home-box-flex" style="text-align:left;">
            <div class="home-text-title">Once in the Blue Moon</div>
            <div class="home-text-content">Cat & the Fiddle</div>
            <div class="home-text-content">
              <el-button type="danger" @click="gotoTop02">Hot deals from $5.31</el-button>
            </div>
         </div>
      </div>
      <el-image style="width:100%;margin-top:60px;" :src="require('../../assets/home_bottom.png')"></el-image>
    </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import ModelHome from '../viewmodel/ModelHome.vue'

export default {
    extends: ModelHome,
    name: 'home',
    components: {
        BaseMobilePage
    }
}
</script>

<style>
.home {
  padding: 0px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 450px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.home-paragraph-m{
  padding: 60px 20px 0px 20px;
  margin: 0 auto;
  max-width: 1300px;
}
.home-text-title{
  margin-top: 12px;
  font-size: 25px;
  font-weight: bold;
}
.home-text-content{
  margin-top: 10px;
  font-size: 15px;
}
.home-box{
  display: block;
}
.home-box-flex{
  -webkit-box-flex:1;
}
</style>