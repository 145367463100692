<template>
  <base-web-page>
    <template v-slot:main>
      <el-main style="padding:0px;margin:0 auto;width:100%;">
      <el-carousel :interval="4000"  height="450px">
        <el-carousel-item v-for="item in urls" :key="item">
          <el-image style="width:100%;" fit="fill" :src="item"></el-image>
        </el-carousel-item>
      </el-carousel>
      <div class="home-paragraph">
        <div class="home-text-title">Welcome To UShop</div>
        <div class="home-text-content">Catch the best deals on the best products!</div>
      </div>
      <div class="home-paragraph home-box-m">
         <div class="home-box-flex" style="text-align:left;">
           <el-image style="height:450px;width:560px;" fit="cover" :src="require('../../assets/top01.png')"></el-image>
         </div>
         <div class="home-box-flex" style="text-align:left;">
            <div class="home-text-title">Chocolates made by Kempinski Hotel</div>
            <div class="home-text-content">Ucommune Singapore</div>
            <div class="home-text-content">
              <el-button type="danger" @click="gotoTop01">Hot deals from $19.80</el-button>
            </div>
         </div>
      </div>
      <el-divider></el-divider>
      <div class="home-paragraph home-box-m">
         <div class="home-box-flex" style="text-align:left;">
            <div class="home-text-title">Once in the Blue Moon</div>
            <div class="home-text-content">Cat & the Fiddle</div>
            <div class="home-text-content">
              <el-button type="danger" @click="gotoTop02">Hot deals from $5.31</el-button>
            </div>
         </div>
         <div class="home-box-flex" style="text-align:right;">
           <el-image style="height:450px;width:560px;" fit="cover" :src="require('../../assets/top02.jpg')"></el-image>
         </div>
      </div>
      <el-image style="width:100%;margin-top:60px;" :src="require('../../assets/home_bottom.png')"></el-image>
    </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'
import ModelHome from '../viewmodel/ModelHome.vue'

export default {
  extends: ModelHome,
  name: 'Home',
  components: {
    BaseWebPage
  }
}
</script>

<style>
</style>