String.prototype.format = function(args) {
    if (arguments.length > 0) {
      var result = this
      if (arguments.length == 1 && typeof(args) == "object") {
        for (var key in args) {
          var reg = new RegExp("({" + key + "})", "g")
          result = result.replace(reg, args[key])
        }
      } else {
        for (var i = 0; i < arguments.length; i++) {
          if (arguments[i] == undefined) {
            return ""
          } else {
            var reg2 = new RegExp("({[" + i + "]})", "g")
            result = result.replace(reg2, arguments[i])
          }
        }
      }
      return result
    } else {
      return this
    }
}

export var product_get_by_id = '/api/product/{id}'
export var product_list_url = '/api/product/list/{pageIndex}/{pageSize}'
export var product_list_by_category_url = '/api/product/list/{categoryId}/{pageIndex}/{pageSize}'
export var product_create_url = '/api/product'
export var product_update_url = '/api/product'
export var product_delete_url = '/api/product/{id}'
export var product_active_list_url = '/api/product/active/{pageIndex}/{pageSize}'
export var product_active_list_by_category_url = '/api/product/active/{categoryId}/{pageIndex}/{pageSize}'
export var category_list_url = "/api/category/list"
export var category_active_list_url = '/api/category/active'
export var category_create_url = '/api/category/{categoryName}/{enable}'
export var category_update_url = '/api/category/{categoryId}/{categoryName}/{enable}'
export var category_delete_url = '/api/category/{categoryId}'
export var ali_oss_sts = '/api/sts'
export var ali_oss_url = 'https://ucsnackshop.oss-ap-southeast-1.aliyuncs.com/'
export var order_create_url = '/api/order'
export var order_client_list = '/api/order/client/list'
export var order_client_status_list = '/api/order/client/{status}/{pageSize}/{pageIndex}'
export var order_check_url = '/api/order/status/{orderId}'
export var order_all_list = '/api/order/admin/list'
export var order_by_id_url = '/api/order/{orderId}'
export var order_delivered_url = '/api/order/delivered/{orderId}'
export var order_send_daily_email = '/api/order/sendDailyEmail'
export var receipt_by_id_url = '/api/receipt/{orderId}'
export var merchant_all_list_url = '/api/merchant/list'
export var merchant_active_list_url = '/api/merchant/list/active'
export var merchant_create_url = '/api/merchant'
export var merchant_update_url = '/api/merchant'
export var merchant_delete_url = '/api/merchant/{id}'
export var marchant_deliver_url = '/api/merchant/deliver'
export var location_list_url = '/api/location/list'
export var user_code_generate_url = '/api/client/code/gen/{count}'
export var user_change_password_url = '/api/client/changePassword'
export var user_login_url = '/api/login'
export var user_m_login_url = '/api/mlogin/{id}'
export var user_register_url = '/api/client'
export var user_list_url = '/api/client/{locationId}/{pageIndex}/{pageSize}'
export var user_check_jwt = '/api/client/jwt'
export var user_join_member_url = '/api/client/member/join'
export var user_set_member_url = '/api/client/member/set/{clientId}/{isMember}'
export var user_login_scan_code_url = '/api/loginScanCode'
export var user_login_scan_code_query_url = '/api/loginScanCode/{code}'
export var user_get_by_id = '/api/client'
export var user_points_history = '/api/client/points/{pageIndex}/{pageSize}'
export var user_order_stat = '/api/client/orderStat'
export var user_login_mtoken = "/api/mtoken"
export var hotel_create_url = '/api/hotel'
export var hotel_list_url = '/api/hotel/list'
export var hotel_book_url = '/api/hotel/book'
export var hotel_user_book_list = '/api/hotel/client/books/{pageIndex}/{pageSize}'
export var hotel_booking_list_url = '/api/hotel/books/list/{pageIndex}/{pageSize}'
export var review_create_url = '/api/review'
export var review_get_by_order_id_url = '/api/review/order/{orderId}'
export var review_get_product_stat_url = '/api/review/stat/product/{productId}'