import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import client from './modules/client'
import product from './modules/product'
import category from './modules/category'
import cart from './modules/cart'
import order from './modules/order'
import merchant from './modules/merchant'
import util from './modules/util'
import hotel from './modules/hotel'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cart,
    category,
    client,
    product,
    order,
    merchant,
    util,
    hotel
  },
  plugins: [createPersistedState()]
})
