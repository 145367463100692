import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/frontweb/Home.vue'
import MobileHome from '../views/mobileweb/Home.vue'

Vue.use(VueRouter)

const pc_routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/old',
    name: 'HomeOld',
    component: () => import('../views/backweb/HomeOld.vue')
  },
  {
    path: '/category/:categoryName',
    name: 'ProductList',
    component: () => import('../views/frontweb/ProductList.vue')
  },
  {
    path: '/product/:productId',
    name: 'Product',
    component: () => import('../views/frontweb/Product.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/frontweb/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/frontweb/Register.vue')
  },
  {
    path: '/account',
    name: 'myAccount',
    component: () => import('../views/frontweb/MyAccount.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/frontweb/Checkout.vue')
  },
  {
    path: '/order/:orderId',
    name: 'Order',
    component: () => import('../views/frontweb/OrderDetail.vue')
  },
  {
    path: '/point',
    name: 'Point',
    component: () => import('../views/frontweb/Point.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/frontweb/Orders.vue')
  },
  {
    path: '/review/:orderId',
    name: 'review',
    component: () => import('../views/frontweb/Review.vue')
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: () => import('../views/frontweb/Hotel.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/frontweb/Cart.vue'),
  },
  {
    path: '/receipt/:orderId',
    name: 'receipt',
    component: () => import('../views/frontweb/Receipt.vue')
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('../views/frontweb/PrivacyPolicy.vue'),
  },
  {
    path: '/pages/terms-of-service',
    name: 'termsOfService',
    component: () => import('../views/frontweb/TermsOfService.vue')
  },
  {
    path: '/pages/maintenance',
    name: 'maintenance',
    component: () => import('../views/frontweb/Maintenance.vue')
  },
  {
    path: '/pages/rent-view/:officeName',
    name: 'rentManagement',
    component: () => import('../views/frontweb/RentManagement.vue')
  },
  {
    path: '/pages/uclub',
    name: 'uclub',
    component: () => import('../views/frontweb/UClub.vue'),
    meta: {
      title: 'UClub'
    }
  },
  {
    path: '/mobileRedirect',
    name: 'mobileRedirect',
    component: () => import('../views/frontweb/MobileRedirect.vue')
  },
]

const mobile_routes = [
  {
    path: '/',
    name: 'home',
    component: MobileHome,
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: ()=> import('../views/mobileweb/Hotel.vue'),
    meta: {
      title: 'Hotel'
    }
  },
  {
    path: '/receipt/:orderId',
    name: 'receipt',
    component: () => import('../views/mobileweb/Receipt.vue')
  },
  {
    path: '/category/:categoryName',
    name: 'ProductList',
    component: () => import('../views/mobileweb/ProductList.vue'),
  },
  {
    path: '/product/:productId',
    name: 'Product',
    component: () => import('../views/mobileweb/Product.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/mobileweb/Checkout.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/mobileweb/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/mobileweb/Register.vue')
  },
  {
    path: '/account',
    name: 'myAccount',
    component: () => import('../views/mobileweb/MyAccount.vue')
  },
  {
    path: '/point',
    name: 'Point',
    component: () => import('../views/mobileweb/Point.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/mobileweb/Orders.vue')
  },
  {
    path: '/order/:orderId',
    name: 'Order',
    component: () => import('../views/mobileweb/OrderDetail.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/mobileweb/Cart.vue'),
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('../views/mobileweb/PrivacyPolicy.vue'),
  },
  {
    path: '/review/:orderId',
    name: 'review',
    component: () => import('../views/mobileweb/Review.vue')
  },
  {
    path: '/mobileRedirect',
    name: 'mobileRedirect',
    component: () => import('../views/mobileweb/MobileRedirect.vue')
  },
  {
    path: '/pages/terms-of-service',
    name: 'termsOfService',
    component: () => import('../views/mobileweb/TermsOfService.vue')
  },
  {
    path: '/pages/maintenance',
    name: 'maintenance',
    component: () => import('../views/mobileweb/Maintenance.vue')
  },
  {
    path: '/pages/rent-view/:officeName',
    name: 'rentManagement',
    component: () => import('../views/mobileweb/RentManagement.vue')
  }
]

function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  console.log('inMobile', navigator.maxTouchPoints)
  return flag || navigator.maxTouchPoints > 1;
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: isMobile() ? mobile_routes : pc_routes
})

export default router
