<template>
    <el-header v-if="htmlHeader === 1" id="header" style="padding:0px;margin:0px;" height="200px">
        <div style="width:100%;background-color:#fff;">
          <el-row  style="max-width:1300px;padding:10px;margin-top:30px;background-color:#fff;">
              <el-col :span="24" style="text-align:center;">
                  <i class="el-icon-back" style="font-size:24px;float:left;margin-top:20px;margin-left:5px;font-weight:bold;" @click="backNavClick"></i>
                  <el-image fit="fill" class="logo-img-2-m" :src="require('../../assets/ushop3.png')" style="cursor:pointer;margin-top:8px;" @click="homePageClick"></el-image>
              </el-col>
              <el-col :span="15">
                  <el-menu text-color="#000" mode="horizontal" style="margin-top:0px;margin-left:-10px;" @select="handleMenuSelect">
                      <el-submenu index="shop">
                        <template slot="title">SHOP</template>
                        <el-menu-item index="all">All products</el-menu-item>
                        <el-menu-item v-for="item in categories" :index="item.name" :key="item.ID">{{item.name}}</el-menu-item>
                      </el-submenu>
                       <!--<el-menu-item index="hotels">HOTEL</el-menu-item>-->
                  </el-menu>
              </el-col>
              <el-col :span="9" style="text-align:right;margin-top:20px;padding-right:10px;">
                  <el-badge :value="goodList.length" style="cursor:pointer;margin-right:20px;">
                      <i class="el-icon-shopping-cart-1" style="font-size:24px;cursor:pointer;" @click="cartClick"></i>
                  </el-badge>
                  <el-badge>
                      <i class="el-icon-user" style="font-size:24px;cursor:pointer;" @click="userClick"></i>
                  </el-badge>
              </el-col>
          </el-row>
          <div class="anouncement-div">
              <!--MEMBER FREE SHIPPING | NO PRICE LIMIT
              <div style="position:absolute;top:4px;right:10px;"><i class="el-icon-close" style="color:#fff;"></i></div>-->
          </div>
        </div>
    </el-header>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
    name: 'pageAppHeader',
    data(){
        return {
            selectCategory: {
                ID: 0,
                name: 'All Categories'
            },
            from: ''
        }
    },
    computed: {
        ...mapState('client', [
            'client',
            'token',
            'tokenTime',
            'browerFrom',
            'htmlHeader'
        ]),
        ...mapGetters('client', ['isMember']),
        ...mapState('cart', [
            'goodList',
            'showCart'
        ]),
        ...mapGetters('cart', ['cartTotal', 'cartMemberTotal']),
        ...mapState('category', ['categories']),
        cartVisible: {
            get(){
                return this.showCart
            },
            set(val){
                this.setShowCart({
                    showCart: val
                })
            }
        },
        isTokenOutOfDate() {
            if(this.token.length == 0){
                return true
            }
            var ts = new Date().getTime() - this.tokenTime
            return ts / 1000 > 3600 * 24 * 3
        },
        isMember: function() {
            if (this.client === null) {
                return false
            }
            return this.client.is_member
        },
        cartTotal: function(){
           var total = 0 
           if(this.goodList.length == 0) {
               return '$0.00'
           }
           for(var i=0; i < this.goodList.length; i++){
               total += this.goodList[i].sku.price * this.goodList[i].amount
           }
           console.log(this.goodList)
           return '$' + (total/100.0).toFixed(2)
        },
        cartMemberTotal: function(){
           var total = 0 
           if(this.goodList.length == 0) {
               return '$0.00'
           }
           for(var i=0; i < this.goodList.length; i++){
               total += this.goodList[i].sku.member_price * this.goodList[i].amount
           }
           console.log(this.goodList)
           return '$' + (total/100.0).toFixed(2)
        },
        cartCount: function(){
            var count = 0
            for(var i=0; i < this.goodList.length; i++){
                count += this.goodList[i].amount
            }
            return count
        },
    },
    mounted(){
        this.from = this.$route.query.from
        this.init()
    },
    methods: {
        ...mapActions('category', [
            'getActiveCategoryList'
        ]),
        ...mapActions('cart', [
            'setShowCart',
            'clearGoods'
        ]),
        init(){
            this.getActiveCategoryList()
        },
        cartClick(){
            console.log('cart click')
            window.location.href = '/cart'
        },
        userClick(){
            console.log('user click')
            if(this.isTokenOutOfDate){
                window.location.href = '/login'
            } else {
                window.location.href = '/account'
            }
        },
        homePageClick(){
            window.location.href = '/'
        },
        handleMenuSelect(key, keyPath){
            console.log(key, keyPath)
            if(key === 'hotels') {
                window.location.href = '/hotels'
            } else {
                window.location.href = '/category/' + key
            }
        },
        clearCartClick() {
            this.clearGoods()
        },
        checkoutClick(){
            if(this.goodList.length == 0) return
             this.setShowCart({
                showCart: false
            })
            if(this.isTokenOutOfDate){
                this.$message({
                    message: 'Login is needed before checkout',
                    type: 'warning'
                })
                window.location.href = '/login?callbackUrl=' + encodeURI('/checkout')
            } else {
                window.location.href = '/checkout'
            }
        },
        backNavClick() {
            window.location.href = 'js://webview?action=back'
        }
    }
}
</script>

<style scoped>
#header {
    background-color: #fff;
    width: 100%;
}
.logo-img-2-m {
    height:40px;
    width: 99px;
    margin-left: -10px;
}
.header-title {
    font-size: 24px;
    line-height: 60px;
}
.login-btn {
    width:60px;
    margin-top:10px;
}
.el-drawer.rtl{
    overflow-y: scroll;
}
.anouncement-div{
    position: relative;
    background-color:#cc471a;
    color:#fff;
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
}
.el-submenu__title{
    font-size: 16px !important;
    font-weight: 400 !important;
}
.el-menu-item{
    font-size: 16px !important;
}
.el-drawer.rtl {
    overflow: auto !important;
}
.cart-total-price {
    color: #F56C6C;
}
.cart-item-div {
    padding: 0px 20px;
}
</style>