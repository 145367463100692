<template>
  <el-container id="baseWebPage" :class="browserFrom === 'app' ? 'baseWebPageUClub' : ''" direction="vertical">
    <page-app-header v-if="isInApp"></page-app-header>
    <page-header v-else></page-header>
    <slot name="main">
      <el-main style="padding:40px 0px;margin:0 auto;width:100%;max-width:1300px;">
        <el-empty description="No Content"></el-empty>
      </el-main>
    </slot>
    <page-footer v-if="!isInApp"></page-footer>
  </el-container>
</template>

<script>
import PageHeader from '../../components/mobile/PageHeader.vue'
import PageAppHeader from '../../components/mobile/PageAppHeader.vue'
import PageFooter from '../../components/mobile/PageFooter.vue'
import {mapState, mapActions} from 'vuex'

export default {
  name: 'baseMobilePage',
  components: {
    PageHeader,
    PageAppHeader,
    PageFooter
  },
  computed: {
    ...mapState('client', ['browserFrom', 'htmlHeader']),
    isInApp() {
      console.log('browserFrom',this.browserFrom, this.browserFrom === 'app')
      return this.browserFrom === 'app'
    }
  },
  /*mounted() {
    if(this.htmlHeader === 1) {
      return
    }
    var pageHader = this.$route.query.pageHeader
    if(pageHader === undefined || pageHader.length === 0) {
      pageHader = 0
    }  else {
      pageHader = parseInt(pageHader)
    }
    this.setHtmlHeader(pageHader)
  },*/
  methods: {
    ...mapActions('client', ['appLogin', 'setFrom', 'setHtmlHeader'])
  }
}
</script>

<style scoped>
.baseWebPageUClub {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>