import axios from 'axios'
import {
    user_login_url,
    user_m_login_url,
    user_register_url,
    user_list_url,
    user_check_jwt,
    user_code_generate_url,
    user_join_member_url,
    user_set_member_url,
    user_login_scan_code_url,
    user_login_scan_code_query_url,
    user_get_by_id,
    user_points_history,
    user_order_stat,
    user_login_mtoken,
    user_change_password_url
} from './urls'

var userApi = {
    loginWithMToken(token){
        return new Promise((resolve, reject) => {
            axios.get(user_login_mtoken, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    generateCode(count, token){
        var url = user_code_generate_url.format({
            count:count
        })
        return new Promise((resolve, reject) => {
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    checkJwt(token){
        return new Promise((resolve, reject)=>{
            axios.get(user_check_jwt, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve()
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    mLogin(id){
        var url = user_m_login_url.format({
            id:id
        })
        return new Promise((resolve, reject)=>{
            axios.get(url).then(resp=>{
                console.log(resp)
                if(resp.status === 200 && resp.data.code === 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    changePassword(data, token) {
        return new Promise((resolve, reject) => {
            axios.post(user_change_password_url, data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                resolve(resp)
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    },
    login(data){
        return new Promise((resolve, reject)=>{
            axios.post(user_login_url, data).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(() =>{
                reject({code:500, message:'ERROR'})
            })
        })
    },
    register(data){
        return new Promise((resolve, reject)=>{
            axios.post(user_register_url, data).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    console.log(resp.data)
                    reject(resp.data)
                }
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    },
    list(id, index, size, token){
        return new Promise((resolve, reject)=>{
            var url = user_list_url.format({
                locationId: id,
                pageSize: size,
                pageIndex: index
            })
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    },
    joinMember(code, token) {
        return new Promise((resolve, reject)=>{
            axios.post(user_join_member_url,{
                invite_code: code
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp.data)
                }
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    setMember(client_id, is_member, token) {
        var url = user_set_member_url.format({
            clientId: client_id,
            isMember: is_member
        })
        return new Promise((resolve, reject) => {
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                if(resp.status == 200 && resp.data.code == 200){
                    resolve()
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    },
    getLoginScanCode() {
        return new Promise((resolve, reject) => {
            axios.get(user_login_scan_code_url).then(resp=>{
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    },
    queryLoginScanCode(code) {
        var url = user_login_scan_code_query_url.format({
            code: code,
        })
        return new Promise((resolve, reject) => {
            axios.get(url).then(resp=>{
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    },
    getClientById(token){
        return new Promise((resolve, reject)=>{
            axios.get(user_get_by_id, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    },
    getClientPointHistory(token, pageIndex, pageSize){
        var url = user_points_history.format({
            pageIndex: pageIndex,
            pageSize: pageSize
        })
        return new Promise((resolve, reject)=>{
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    },
    getClientOrderState(token){
        return new Promise((resolve, reject)=>{
            axios.get(user_order_stat, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject()
                }
            }).catch(()=>{
                reject()
            })
        })
    }
}

export default userApi