import categoryApi from '../../api/category'

const state = () => ({
    categories: []
})

const getters = {

}

const mutations = {
    setCategories(state, payload){
        state.categories = payload.categories
    },
    clearCatgories(state){
        state.categories = []
    }
}

const actions = {
    clearCatgories({commit}){
        commit('clearCatgories')
    },
    getCategoryList({commit}){
        return new Promise((resolve, reject)=>{
            categoryApi.getList().then(result=>{
                commit('setCategories', {
                    categories: result
                })
                resolve()
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    getActiveCategoryList({commit}){
        return new Promise((resolve, reject)=>{
            categoryApi.getActiveList().then(result=>{
                commit('setCategories', {
                    categories: result
                })
                resolve()
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    createCategory({dispatch, rootState}, payload) {
        return new Promise((resolve, reject)=>{
            categoryApi.create(payload.name, payload.enable, rootState.client.token).then(()=>{
                dispatch('getCategoryList')
                resolve()
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    updateCategory({dispatch, rootState}, payload) {
        return new Promise((resolve, reject)=>{
            categoryApi.update(payload.categoryId, payload.categoryName, payload.enable, rootState.client.token).then(()=>{
                dispatch('getCategoryList')
                resolve()
            }).catch(err=>{
                console.log(err)
                reject()
            })
        })
    },
    deleteCategory({dispatch, rootState}, payload){
        return new Promise((resolve, reject)=>{
            categoryApi.delete(payload.categoryId, rootState.client.token).then(()=>{
                dispatch('getCategoryList')
                resolve()
            }).catch(err=>{
                reject(err)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}