import axios from 'axios'
import {
 location_list_url
} from './urls'

var utliApi = {
    getLocations(){
        return new Promise((resolve, reject)=>{
            axios.get(location_list_url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                reject(err)
            })
        })
    }
}

export default utliApi