import utilApi from '../../api/util'

const state = () => ({
    locations: []
})

const getters = {

}

const mutations = {
    getLocations(state, payload){
        state.locations = payload.locations
    }
}

const actions = {
    getLocations({commit}){
        return new Promise((resolve, reject)=>{
            utilApi.getLocations().then(result=>{
                commit('getLocations', {
                    locations: result
                })
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}